<template>
  <div>
    <h3>Quantité ventes annulées</h3>
    <div class="card">
      <canvas id="cancelledSalesChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "QteVentesAnnulees",
  data() {
    return {
      chart: null,
      venteCount: 0,
      baseUrl: this.$store.state.api_epharma_ventes,

    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(this.baseUrl + "/api/statistiques/ventes-annulees");
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données.");
        }
        const data = await response.json();
        this.venteCount = data.total_annule || 0;
        this.renderChart();
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    renderChart() {
      const ctx = document.getElementById("cancelledSalesChart").getContext("2d");

      if (this.chart) {
        this.chart.destroy(); // Détruire l'ancien graphique pour éviter les conflits
      }

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Ventes annulées", "Autres ventes"],
          datasets: [
            {
              data: [this.venteCount, 100 - this.venteCount],
              backgroundColor: ["#FF6384", "#36A2EB"],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
        },
      });
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
</style>