<template>
    <div>
      <h3>Importer un fichier Excel</h3>
      <input type="file" @change="handleFileChange" />
  
      <!-- Bouton pour envoyer les données -->
      <button v-if="excelData.length" @click="uploadData">Envoyer les données</button>
  
      <!-- Affichage du JSON formaté -->
      <pre v-if="formattedJson">{{ formattedJson }}</pre>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as XLSX from "xlsx";
  
  export default {
    data() {
      return {
        excelData: [], // Pour stocker les données Excel avec en-tête
      };
    },
    computed: {
      formattedJson() {
        return JSON.stringify(this.excelData, null, 2);
      },
    },
    methods: {
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
  
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headers = jsonData[0];
            const rows = jsonData.slice(1);
  
            this.excelData = rows.map(row => {
              const rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              return rowData;
            });
          };
          reader.readAsArrayBuffer(file);
        }
      },
      uploadData() {
        axios.post('/api/import-produits', { produits: this.excelData })
          .then(response => {
            console.log('Données envoyées avec succès', response.data);
          })
          .catch(error => {
            console.error('Erreur lors de l\'envoi des données', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  pre {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 4px;
    max-height: 400px;
    overflow: auto;
  }
  </style>
  


<!-- <template>
    <div>
        <h3>Importer un fichier Excel</h3>
        <input type="file" @change="handleFileChange" />

         Affichage du JSON formaté 
        <pre v-if="formattedJson">{{ formattedJson }}</pre>
    </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
    data() {
        return {
            excelData: [], // Pour stocker les données Excel avec en-tête
        };
    },
    computed: {
        formattedJson() {
            // Retourne le JSON formaté avec un indentation de 2 espaces
            return JSON.stringify(this.excelData, null, 2);
        },
    },
    methods: {
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: "array" });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];

                    // Récupère les données Excel en utilisant la première ligne comme en-tête
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    // Séparation de la première ligne (en-tête) des données
                    const headers = jsonData[0]; // Première ligne : en-tête
                    const rows = jsonData.slice(1); // Les autres lignes : données

                    // Conversion des lignes en objets avec les clés de l'en-tête
                    this.excelData = rows.map(row => {
                        const rowData = {};
                        headers.forEach((header, index) => {
                            rowData[header] = row[index]; // Associer chaque en-tête à sa valeur dans la ligne
                        });
                        return rowData;
                    });
                };
                reader.readAsArrayBuffer(file);
            }
        },
    },
};
</script>

<style scoped>
pre {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 4px;
    max-height: 400px;
    overflow: auto;
}
</style> -->