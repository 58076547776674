<template>
    <div class="container">
      <h3>Panier Moyen par Utilisateur</h3>
      <canvas id="panierMoyenChart"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from "chart.js/auto";
  
  export default {
    name: "PanierMoyen",
    data() {
      return {
        chartInstance: null,
        users: [],
        paniersMoyens: [],
        baseUrl: this.$store.state.api_epharma_ventes,
      };
    },
    methods: {
      async fetchPanierMoyen() {
        try {
          const response = await fetch(this.baseUrl+ "/api/statistiques/panier-moyen");
          const data = await response.json();
  
          // Extraire les utilisateurs et leurs paniers moyens
          this.users = data.map((item) => item.user);
          this.paniersMoyens = data.map((item) => item.panier_moyen);
  
          // Afficher les données dans le graphique
          this.renderChart();
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      },
      renderChart() {
        const ctx = document.getElementById("panierMoyenChart").getContext("2d");
  
        if (this.chartInstance) {
          this.chartInstance.destroy(); // Détruire le graphique existant avant d'en créer un nouveau
        }
  
        this.chartInstance = new Chart(ctx, {
          type: "bar",
          data: {
            labels: this.users,
            datasets: [
              {
                label: "Panier Moyen (FCFA)",
                data: this.paniersMoyens,
                backgroundColor: "rgba(54, 162, 235, 0.6)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: { display: true },
            },
          },
        });
      },
    },
    mounted() {
      this.fetchPanierMoyen();
    },
  };
  </script>
  
  <style>
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>
 