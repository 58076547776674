<template>
  <div class="loading-overlay">
    <div class="loading-container">
      <span class="loading-spinner">
        <i class="fas fa-hourglass-half text-primary" style="font-size: 80px;"></i>
      </span>
    </div>
  </div>
</template>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Couleur de fond semi-transparente */
  z-index: 9999;
  /* Assurez-vous que l'overlay est au-dessus de tout le reste */
}

.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  display: inline-block;
  font-size: 50px;
  color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>