// src/services/formatService.ts
import moment from 'moment';

export const FormatService = {
  // Formatage d'une somme d'argent
  moneyFormat(varMoney: number): string {
    if (varMoney) {
      return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA';
    }
    return '0 FCFA';
  },

  // Formatage d'une date
  dateFormat(varDate: string | Date): string {
    if (varDate && varDate !== '') {
      const newDate = new Date(varDate);
      if (!isNaN(newDate.getTime())) {
        return moment(newDate).format('DD/MM/YYYY');
      }
    }
    return 'Date invalide';
  },
};
