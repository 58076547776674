<template>
    <div>
      <h3>Chiffre d'affaire par utilisateur</h3>
      <canvas id="ventesDoughnutChart"></canvas>
    </div>
  </template>
  
  <script>
  import { Chart, DoughnutController, ArcElement, Tooltip, Legend } from "chart.js";
  
  Chart.register(DoughnutController, ArcElement, Tooltip, Legend);
  
  export default {
    name: "ChiffreAffaireUtilisateur",
    data() {
      return {
        chart: null,
        labels: [],
        data: [],
        baseUrl: this.$store.state.api_epharma_ventes,
      };
    },
    methods: {
      async fetchVentesData() {
        try {
          const response = await fetch(this.baseUrl +'/api/statistiques/total-ventes-utilisateur'); // URL de l'API Laravel
          const result = await response.json();
  
          // Préparer les labels et les données
          this.labels = result.map((item) => item.user);
          this.data = result.map((item) => item.total_ventes);
  
          this.renderChart();
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      },
      renderChart() {
        const ctx = document.getElementById("ventesDoughnutChart").getContext("2d");
  
        if (this.chart) {
          this.chart.destroy(); // Détruire le graphique existant si nécessaire
        }
  
        this.chart = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: this.labels,
            datasets: [
              {
                data: this.data,
                backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#4BC0C0",
                  "#9966FF",
                  "#FF9F40",
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
            },
          },
        });
      },
    },
    mounted() {
      this.fetchVentesData();
    },
  };
  </script>
  
  <style scoped>
  canvas {
    max-width: 100%;
    height: auto;
  }
  </style>