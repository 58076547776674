<template>
    <form @submit.prevent="searchByKeyword" class="row d-flex justify-content-center align-items-center">
       <div class="col-md">
            <input type="text" class="form-control form-control-sm m-0 search-form" placeholder="Tapez le mot clé à rechercher" v-model="keyWord" @change="onInputChange">
       </div>
       <div class="col-md-1 col" v-if="keyWord != ''">
            <button type="button" class="btn btn-sm btn-danger" @click="cleanInput()" title="Effacer"> 
                <i class="fa-solid fa-xmark text-white" ></i>
            </button>
       </div>
       <div class="col-md-2 col">
            <button type="submit" class="btn btn-sm btn-primary btn-block" title="Rechercher">
                <i class="fa-solid fa-magnifying-glass ml-2" ></i>
            </button>
       </div>
    </form>
</template>

<script>
    export default {
        name:"InputRecherche",
        data() {
            return {
                keyWord: ''
            }
        },
        methods: {
            searchByKeyword() {
                this.$emit('set-keyword-filter', this.keyWord)
            },
            cleanInput() {
                this.keyWord = '';
                this.$emit('set-keyword-filter', this.keyWord)
            },
            onInputChange() {
                if (this.keyWord == '') {
                    this.$emit('set-keyword-filter', this.keyWord)
                }
            }
        },
    }
</script>
