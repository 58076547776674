<template>
  <div class="container mt-5">
    <h3 class="text-center">Nombre de clients servis par Utilisateur</h3>
    <div class="card">
      <canvas id="ventesUtilisateurChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'StatClientServis',
  data() {
    return {
      ventesData: [], // Contiendra les données pour le graphique
      ventesLabels: [], // Contiendra les noms des utilisateurs
      baseUrl: this.$store.state.api_epharma_ventes,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(this.baseUrl + '/api/statistiques/clients-utilisateur');
        const data = await response.json();
        this.ventesData = data.map((item) => item.nombre_ventes);
        this.ventesLabels = data.map((item) => item.user);
        this.renderChart();
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    },
    renderChart() {
      const ctx = document.getElementById('ventesUtilisateurChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.ventesLabels,
          datasets: [
            {
              label: 'Nombre de ventes',
              data: this.ventesData,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style>
.container {
  max-width: 800px;
  margin: auto;
}
</style>