<template>
    <EpharmaTemplate :withHeader="false">
        <template #content>

            <div class="container py-5 bg-white">
                <TabView>
                    <TabPanel header="GENERAL">
                        <InfosPharmacie></InfosPharmacie>
                    </TabPanel>
                    <TabPanel header="INFORMATIONS SUR LES PRODUITS">
                        <InfosProduit></InfosProduit>
                    </TabPanel>
                </TabView>
            </div>
            <!-- <div class="mx-auto pl-5 pr-5 mt-4">
                <p class="h5">Paramètre de sauvegarde automatisé</p>
                <p class="h5">Enregistrez les paramètres de votre serveur de sauvegarde distant.</p>
                <div class="d-flex justify-content-between">
                    <div class="mt-4">
                        <p>Remote IP</p>
                        <input class="bg-inputParametre border border-[#ccc]" id="input1" type="text" minlength="7"
                            maxlength="15" size="15"
                            pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$">
                    </div>
                    <div class="mt-4">
                        <p>Remote Port</p>
                        <input class="bg-inputParametre border border-[#ccc]" id="input2" type="number" minlength="7"
                            maxlength="15" size="15"
                            pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$">
                    </div>
                    <div class="mt-4">
                        <p>Nom d'utilisateurs(DB)</p>
                        <input class="bg-inputParametre border border-[#ccc]" id="input3" type="text" minlength="7"
                            maxlength="15" size="15"
                            pattern="^((\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$">
                    </div>
                </div>
                <div class="mt-3">
                    <p>Mot de passe(DB)</p>
                    <input class="bg-inputParametre border border-[#ccc]" id="input4" type="text">
                </div>
                <button class="mt-4 bg-bleu2 text-white border border-bleu2">Enregistrer les modifications</button>
                <p class="mt-4">Backup manuel</p>
                <p>Utilisez la commande <span class="font-weight-bold">DUMP</span> pour enregistrer une archive de votre
                    base
                    données. Vous pouvez ensuite télécharger la sauvegarde et la conserver pour une restauration en cas
                    <br> de
                    problème. <br> La commande <span class="font-weight-bold"> RESTORE</span> sert à déployer
                    manuellement une
                    archive de la base de données local sur le serveur de sauvegarde distant.</p>
                <p class="text-gri2 font-weight-bold">Dernière sauvegarde (Dump)</p>
                <RouterLink>
                    <p><ins>Télécharger le fichier</ins></p>
                </RouterLink>
                <p class="text-gri2 font-weight-bold">Dernière restauration automatique (Restore) <br> <span
                        class="text-dark">26/04/2024 22:44</span> </p>
                <div class="d-flex">
                    <button class="bg-bleu2 text-white border border-bleu2">Dump</button>
                    <button class='bg-bleu2 text-white ml-3 border border-bleu2'>Restore</button>
                </div><br><br>
                <p class="font-weight-bold">Mise à jour</p>
                <p>Votre infrastructure ePharma est constitué de deux applications dont les mises à jours sont gérées de
                    façon
                    indépendente. Les boutons ci-dessous vous donne la possibilité de <br> télécharger les nouvelles
                    mise à jour
                    de chaque application si il y'en a.<br>
                    *En cas de mise à jour majeur, vous recevrez une notification de la part du support technique de
                    ePharma.
                </p>
                <p class="text-gri2 font-weight-bold">Actions</p>
                <div class="d-flex">
                    <button class="bg-bleu2 text-white border border-bleu2">Vérifier et Télécharger les mises à jours
                        Backend</button>
                    <button class='bg-bleu2 text-white ml-3 border border-bleu2'>Vérifier et Télécharger les mises à
                        jour
                        Frontend</button>
                </div><br><br>
                <div class="d-flex">
                    <div>
                        <p class="font-weight-bold">Accès à distance</p>
                        <p>Enregistrer les nouveaux paramètres d'accès distant.</p>
                    </div>
                    <div class="ml-4">
                        <p class="font-weight-bold">Version</p>
                        <p>15.05.2023 2.1.1</p>
                    </div>
                </div>

                <div class="d-flex">
                    <div>
                        <p>IP Backend</p>
                        <button class="bg-bleu2 text-white border border-bleu2">Vérifier IP Backend</button>
                    </div>
                    <div class="ml-4">
                        <p class="ml-5">IP SSH</p>
                        <button class="bg-bleu2 text-white border border-bleu2">Vérifier IP SSH</button>
                    </div>
                </div>
            </div> -->
        </template>
    </EpharmaTemplate>


</template>

<script>
import { defineComponent } from 'vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import InfosPharmacie from './InfosPharmacie.vue'
import InfosProduit from './InfosProduit.vue'


export default defineComponent({
    components: { InfosPharmacie, InfosProduit },
    data() {
        return {
            baseUrl: this.$store.state.api_epharma_comptes,
            isLoading: false,
        }
    },
    computed: {
        formData: {
            get() {
                return this.$store.getters.pharmacie;  // Accéder aux données via Vuex
            },
            set(value) {
                this.$store.commit('setPharmacieDatas', value);  // Mettre à jour Vuex quand on modifie les inputs
            },
        },
    },
    methods: {
        async saveCompanyInfo() {
            this.isLoading = true
            this.axios.post('/api/pharmacie-info', this.formData).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },


    },

})
</script>