<template>
    <EpharmaTemplate :withHeader="false">
        <template #content>
            <div class="container pt-3">
                <ProgressBar class="my-1" v-if="isLoading === true" mode="indeterminate" style="height: 6px">
                </ProgressBar>
                <div class="d-flex mx-auto align-items-center mb-3">
                    <div class="h2 btn btn-lg bg-dark text-white mr-2">
                        N° <span v-if="firstSelectedReservation != null">{{ firstSelectedReservation.position
                            }}</span>
                    </div>
                    <div class="d-flex " style="max-width: 100vh; overflow-x: auto;">
                        <button type="button" class="btn rounded-circle mx-1 mb-2"
                            v-for="(reservation, index) in reservations" :key="index" :id="'reserv' + reservation.id"
                            :class="[firstSelectedReservation != null && firstSelectedReservation.id == reservation.id ? 'bg-white border-primary' : 'bg-white border-light']"
                            @click="select_reservation(reservation)">
                            {{ reservation.position }}
                        </button>
                    </div>
                    <div class="d-flex ml-auto">

                        <div class="mx-2 text-primary" style="cursor: pointer;" @click="getReservations"
                            title="Rafraichir">
                            <i class="fa-solid fa-rotate bg-white p-2 rounded-circle" style="font-size: 2rem;"></i>
                        </div>
                        <div class="mx-2 text-danger" style="cursor: pointer;" @click="logout()" title="Se déconnecter">
                            <i class="fa-solid fa-right-to-bracket bg-white p-2 rounded-circle"
                                style="font-size: 2rem;"></i>
                        </div>

                    </div>
                </div>

                <div class="row d-flex justify-content-center" v-if="isLoading === false && selectedReservation" >
                    <div class="col-md-8 h-fixed ">
                        <div class="position-relative">
                            <div id="overlay" class="overlay" v-if="selectedReservation.status == 'Termine'"></div>
                            <div class="watermark border-danger px-5" v-if="selectedReservation.status == 'Termine'">
                                Payé Livré
                            </div>

                            <div class="card bg-white mb-3 pb-3 shadow ">
                                <div class="bg-bleuN m-3" v-if="caisse != null">
                                    <h6 class="text-center p-4">{{ caisse.libelle }}</h6>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Client</label>
                                    <input type="text" :value="selectedReservation.client"
                                        class="form-control border border-dark px-[4rem] " style=" border-bottom: 1px solid black ; 
                                        border-left: none; border-right: none; border-top: none; 
                                    background: #fafafa;" disabled>
                                </div>
                            </div>
                            <div class="card body-bg-white shadow">
                                <table class="table table-striped" :key="keyTable">
                                    <thead class="bg-primary text-light">
                                        <tr>
                                            <th scope="col">PRODUIT</th>
                                            <th scope="col">QUANTITE</th>
                                            <th scope="col">COÛT</th>
                                            <th scope="col">% TAUX</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in reservation_produits" :key="item">
                                            <td>{{ item.libelle }}</td>
                                            <td>{{ item.qte }}</td>
                                            <td>{{ moneyFormat(item.prix_de_vente) }}</td>
                                            <td>{{ item.prise_en_charge }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <form class="col-md-4" @submit.prevent="updateReservation">
                        <div class="d-flex justify-content-between">
                            <button type="submit" class="btn btn-black" v-if="!selectedReservation.switch_finish_at && netAPayer != 0">
                                Payer
                            </button>

                            <button type="button" class="btn btn-black" v-else
                                @click="this.printReceipt()">
                                Imprimer
                            </button>

                            <button type="button" class="btn btn-black" @click="openBilletage()">
                                Billetage
                            </button>
                        </div>
                        <div class="bg-black rounded-sm mt-3 p-2  " v-if="netAPayer != 0">
                            <label class="text-white "> TTC</label>
                            <h5 class="text-white">{{ moneyFormat(selectedReservation.montant) }}</h5>
                        </div>
                        <div class="bg-black mt-3 rounded-sm p-3" v-if="netAPayer != 0">
                            <div class="form-group">
                                <label class="text-gri3">Montant reçu</label>
                                <input type="number" v-model="form.amount_gived"
                                    class="form-control border border-dark px-[4rem]" required
                                    :disabled="selectedReservation.switch_finish_at">
                            </div>
                            <div class="form-group">
                                <label class="text-gri3">Difference</label>
                                <input type="number"
                                    :value="(parseFloat(form.amount_gived) - parseFloat(selectedReservation.montant)).toFixed(2)"
                                    class="form-control border border-dark px-[4rem]" step="0.00" disabled>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-else class="col-md-4 my-3 mx-auto">
                    <button type="button" class="btn btn-black" @click="openBilletage()">
                        Billetage
                    </button>
                </div>
                <TicketCaisseReservation v-if="key > 1" :key="key" :reservation="selectedReservation"
                    :amount_gived="form.amount_gived" :netAPayer="netAPayer"
                    :reservation_produits="reservation_produits" v-show="false"></TicketCaisseReservation>
            </div>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
const CreateBilletage = defineAsyncComponent(() => import('../../gestion/billetages/CreateBilletage.vue'));
import TicketCaisseReservation from '@/views/vente/reservations/TicketCaisseReservation.vue'

export default defineComponent({
    components: { TicketCaisseReservation },
    data() {
        return {
            key: 1,
            keyTable: 1,
            table: 'reservations',
            netAPayer: null,
            duplicata: true,
            baseUrl: this.$store.state.api_epharma_ventes,
            isLoading: false,
            firstSelectedReservation: null,
            selectedReservation: null,
            form: {
                amount_gived: 0
            },
            caisse: {},
            reservation_produits: [],
            clientSelected: {},
            clients: [],
            reservations: []
        }
    },
    beforeCreate() {
        if (this.$store.getters.caisseConnected != this.$route.params.id || !this.$store.getters.caisseAuthenticated) {
            this.$router.push({ name: 'caisse' })
        }
    },
    created() {
        this.getCaisse()
    },
    mounted() {
        this.getReservations()
    },
    methods: {
        getProduits() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservation_produits?reservation_id=' + this.selectedReservation.id).then((response) => {
                this.reservation_produits = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })

        },
        refreshSelectedReservation() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservations/' + this.firstSelectedReservation.id).then((response) => {
                this.selectedReservation = response.data.data
                this.keyTable++
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        logout() {
            this.$store.commit('clearAuthenticatedCaisse')
            this.$router.push({ name: 'caisse' })
        },
        getClient() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/clients/' + this.selectedReservation.client_id).then((response) => {
                this.clientSelected = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        openBilletage() {
                this.$dialog.open(CreateBilletage, {
                    props: {
                        header: "Enregistrement du billetage de la caisse " + this.caisse.libelle,
                        style: {
                            width: '60vw',
                        },
                        modal: true
                    },
                    data: this.caisse
                });
        },
        moneyFormat(varMoney) {
            if (varMoney) return new Intl.NumberFormat('de-DE').format(parseFloat(varMoney)) + ' FCFA'
        },
        getCaisse() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/caisses/' + this.$route.params.id).then((response) => {
                this.caisse = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getReservations() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservations/jour' + '?caisse_id=' + this.$route.params.id).then((response) => {
                const reserv = response.data.data
                this.reservations = reserv
                if (reserv.length > 0) {
                    this.firstSelectedReservation = reserv[reserv.length - 1];
                    this.select_reservation(this.firstSelectedReservation)
                }
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        select_reservation(reservation) {
            this.firstSelectedReservation = reservation
            this.selectedReservation = reservation
            
            this.netAPayer = reservation.montant

            this.form = {
                // status: reservation.amount_gived,
                amount_gived: reservation.amount_gived,
                switch_finish_at: reservation.switch_finish_at
            }
            if (!this.selectedReservation.switch_finish_at) {
                this.duplicata = false
            }
            this.getClient()
            this.getProduits()
            this.keyTable++
        },
        updateReservation() {
            if (parseFloat(this.form.amount_gived) < parseFloat(this.selectedReservation.montant)) {
                this.$toast.add({
                    severity: 'warn',
                    summary: 'Oups !',
                    detail: 'La difference ne peut etre négative',
                    life: 7000
                });
                return
            }
            // else if (parseFloat(this.clientSelected.current_remboursement_amount) < parseFloat(this.selectedReservation.total_prise_en_charge)) {
            //     this.$toast.add({
            //         severity: 'warn',
            //         summary: 'Oups !',
            //         detail: 'Le montant de la prise en charge doit etre inferieur au crédit disponible',
            //         life: 7000
            //     });
            //     return
            // }
            this.form.status = 'Termine'
            this.form.switch_finish_at = new Date().toLocaleString('fr-FR')
            this.form.switch_finish_by = this.$store.getters.user.fullname


            this.axios.post(`${this.baseUrl}/api/reservations/${this.selectedReservation.id}/update`, this.form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.selectedReservation.status = 'Termine'
                    this.$toast.add({
                        severity: 'success',
                        detail: 'Vente enregistré',
                        life: 3000
                    });
                    this.refreshSelectedReservation()
                    this.printReceipt()
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        printReceipt() {
            this.key++
            this.refreshSelectedReservation()
        }
    }
})
</script>

<style scoped>
.h-fixed {
    height: 80vh;
    overflow-y: auto;
}

.card .position-relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.2);
    /* Grise l'arrière-plan */
    z-index: 1050;
    pointer-events: all;
    /* Désactive les interactions sur les éléments sous l'overlay */
}

.watermark {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(30deg);
    border-style: dashed;
    color: #ff0022;
    font-size: 2.1em;
    font-weight: bold;
    opacity: 0.5;
    z-index: 1060;
    /* z-index: 1; */
    pointer-events: none;
    /* Désactive les clics sur le filigrane */
}

@page {
    size: 80mm 80mm;
    margin: 0;
}

body {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

#content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid black;
}
</style>
