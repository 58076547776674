<template>
    <div>
        <h3>Produits achetés </h3>
        <div class="card">
            <canvas id="produits-achetes-chart"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'StatistiqueAChat',
    data() {
        return {
            chart: null, // Instance du graphique
            produitLibelles: [], // Noms des produits
            totalQuantites: [], // Quantités totales par produit
            baseUrl: this.$store.state.api_epharma_stock,
        };
    },
    methods: {
        async fetchData() {
            try {
                const response = await fetch(this.baseUrl + '/api/statistiques/produits-achetes');
                const data = await response.json();

                // Préparation des données pour le graphique
                this.produitLibelles = data.map(item => item.produit_libelle);
                this.totalQuantites = data.map(item => item.total_qte);

                // Création du graphique
                this.createChart();
            } catch (error) {
                console.error('Erreur lors du chargement des données :', error);
            }
        },
        createChart() {
            if (this.chart) {
                this.chart.destroy(); // Détruire l'ancien graphique s'il existe
            }
            const ctx = document.getElementById('produits-achetes-chart').getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.produitLibelles,
                    datasets: [
                        {
                            label: 'Quantités totales achetées',
                            data: this.totalQuantites,
                            backgroundColor: 'rgba(75, 192, 192, 0.2)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        legend: { position: 'top' },
                        tooltip: { enabled: true },
                    },
                },
            });
        },
    },
    mounted() {
        this.fetchData(); // Récupérer les données lors du montage du composant
    },
};
</script>

<style scoped>
canvas {
    max-width: 100%;
    height: auto;
}
</style>