<template>
  <div>
    <h3 class="text-center">Difference du Dernier Inventaire</h3>
    <div class="card">
      <canvas id="inventaireChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "DiffDernierInventaire",
  data() {
    return {
      chart: null,
      produits: [],
      ecarts: [],
      baseUrl: this.$store.state.api_epharma_stock,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(this.baseUrl + "/api/statistiques/diff-dernier-inventaire");
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données.");
        }
        const data = await response.json();
        this.produits = data.map((item) => item.produit_libelle);
        this.ecarts = data.map((item) => item.ecart);
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    renderChart() {
      const ctx = document.getElementById("inventaireChart").getContext("2d");

      if (this.chart) {
        this.chart.destroy(); // Détruire le graphique existant pour éviter les conflits
      }

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.produits,
          datasets: [
            {
              label: "Écart",
              data: this.ecarts,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
  async mounted() {
    await this.fetchData();
    this.renderChart();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
</style>