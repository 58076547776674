<template>
  
    <h3>Quantité Produits vendus par mois</h3>
    <div class="card">
      <div class="card-body">
      <canvas id="soldProductsChart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "QteVenteProduit",
  data() {
    return {
      chart: null,
      libelles: [],
      quantites: [],
      baseUrl: this.$store.state.api_epharma_ventes,

    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await fetch(this.baseUrl + "/api/statistiques/sold-products");
        if (!response.ok) {
          throw new Error("Erreur lors du chargement des données.");
        }
        const data = await response.json();
        this.libelles = data.map((item) => item.libelle);
        this.quantites = data.map((item) => item.total_qte);
      } catch (error) {
        console.error("Erreur :", error);
      }
    },
    renderChart() {
      const ctx = document.getElementById("soldProductsChart").getContext("2d");

      if (this.chart) {
        this.chart.destroy(); // Détruire l'ancien graphique pour éviter les conflits
      }

      this.chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: this.libelles,
          datasets: [
            {
              label: "Quantité vendue",
              data: this.quantites,
              backgroundColor: [
                "#FF6384",
                "#36A2EB",
                "#FFCE56",
                "#4BC0C0",
                "#9966FF",
                "#FF9F40",
              ],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  const label = context.label || "";
                  const value = context.raw || 0;
                  return `${label}: ${value}`;
                },
              },
            },
          },
        },
      });
    },
  },
  async mounted() {
    await this.fetchData();
    this.renderChart();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
</style>