<template>
    <div class="container mt-4">
        <h3 class="text-center">Produits du Dernier Inventaire</h3> 
        <div class="card">
            <canvas id="barChart" ref="barChart"></canvas>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    name: 'BarChart',
    data() {
        return {
            produits: [], // Liste des produits
            labels: [], // Labels pour le graphique
            data: [], // Données pour le graphique
            chart: null, // Instance Chart.js
            baseUrl: this.$store.state.api_epharma_stock,
        };
    },
    methods: {
        async fetchData() {
            try {
                const response = await this.axios.get(this.baseUrl + '/api/statistiques/dernier-inventaire');
                if (response.data.data) {
                    this.produits = response.data.data;

                    // Préparer les données pour le graphique
                    this.labels = this.produits.map((produit) => produit.produit_libelle);
                    this.data = this.produits.map((produit) => produit.qte_reelle);

                    // Afficher le graphique
                    this.renderChart();
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        },
        renderChart() {
            const ctx = this.$refs.barChart.getContext('2d');
            if (this.chart) this.chart.destroy(); // Détruire l'ancien graphique si existant

            this.chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.labels,
                    datasets: [
                        {
                            label: 'Quantité Réelle',
                            data: this.data,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                            borderColor: 'rgba(75, 192, 192, 1)',
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                        },
                    },
                },
            });
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>

<style scoped>
.container {
    max-width: 800px;
}
</style>