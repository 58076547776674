
<template>
  <div>
    <h3>Marge sur les achats</h3>
    <div class="card">
      <canvas id="margeChart"></canvas>
    </div>
    
  </div>
</template>

<script>
import { Chart, BarElement, BarController, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";

Chart.register(BarElement, BarController, CategoryScale, LinearScale, Tooltip, Legend);

export default {
  name: "MargeAchat",
  data() {
    return {
      chart: null,
      labels: [],
      data: [],
      baseUrl: this.$store.state.api_epharma_stock,
    };
  },
  methods: {
    async fetchMargeData() {
      try {
        const response = await fetch(this.baseUrl + '/api/marge-benefice'); // Assurez-vous que l'URL correspond à votre route
        const result = await response.json();

        // Préparer les labels et les données
        this.labels = result.map((item) => item.produit_libelle);
        this.data = result.map((item) => item.marge);

        this.renderChart();
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      }
    },
    renderChart() {
      const ctx = document.getElementById("margeChart").getContext("2d");

      if (this.chart) {
        this.chart.destroy(); // Détruire le graphique existant
      }

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Marge sur les achats (Bénéfice)",
              data: this.data,
              backgroundColor: "rgba(75, 192, 192, 0.5)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "top",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
  mounted() {
    this.fetchMargeData();
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  height: auto;
}
</style>