<template>
  <div id="app">
    <!-- <AlertBanner v-if="showAlertBanner" :message="alertMessage" :showUpdateLink="true"
      @close="showAlertBanner = false" /> -->
    <RouterView />
    <ShowToast />
    <DynamicDialog />

    <!-- template des alertes -->
    <ConfirmDialog group="headless">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div class="card card-body rounded surface-overlay" style="width: 18rem;">
          <h1 class="text-center mb-2">
            <span class="bg-warning rounded-pill px-3 py-1">
              <i class="fa-solid fa-triangle-exclamation text-light"></i>
            </span>
          </h1>
          <div class="text-center">
            <h3 class="font-weight-bold mb-2">{{ message.header }}</h3>
            <p class="mb-2">{{ message.message }}</p>
            <div class="d-flex align-items-center justify-content-center">
              <button type="button" @click="acceptCallback" class="btn btn-primary mr-2">OUI</button>
              <button type="button" @click="rejectCallback" class="btn btn-danger">NON</button>
            </div>
          </div>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
// import AlertBanner from './layouts/AlertBanner.vue';
import packageJson from '../package.json'; // Utilisation d'import ES6

export default {
  // components: {
  //   AlertBanner
  // },
  data() {
    return {
      showLoading: true,
      showAlertBanner: false,
      alertMessage: '',
    };
  },
  created() {
    this.getCompanyInfo();
    this.checkHabilitations();
  },
  mounted() {
    // this.checkForUpdates();
  },
  methods: {
    async getCompanyInfo() {
      try {
        const response = await this.axios.get('/api/pharmacie-info');
        const pharmacie = response.data.data;
        this.$store.commit('setPharmacieDatas', pharmacie)
      } catch (error) {
        console.error('Erreur lors du chargement des informations:', error);
      }
    },
    async checkHabilitations() {
      try {
        const response = await this.axios.get('/api/utilisateurs/'+this.$store.getters.user.id);
        const user = response.data.data;
        if (user.is_admin == false){
          this.$store.commit('setUserHabilitations', user)
        }
      } catch (error) {
        console.error('Erreur lors du chargement des habilitations:', error);
      }
    },
    async checkForUpdates() {
      try {
        const response = await this.axios.get('http://127.0.0.1:8009/api/latest-version');
        const latestVersion = response.data.numero_version;
        const currentVersion = packageJson.version;

        if (latestVersion && latestVersion !== currentVersion) {
          this.showAlertBanner = true;
          this.alertMessage = `Une nouvelle version (${latestVersion}) est disponible.`;
        }
      } catch (error) {
        console.error('Erreur lors de la vérification des mises à jour :', error);
      }
    },
  },
};
</script>

<style lang="scss">
@import './style/app.scss';
</style>
